import { Link } from 'react-router';
import { Image } from '@unpic/react';
import dayjs from 'dayjs';
import capitalize from 'lodash/capitalize';
import type { ActivitiesQuery, Money, PublicActivitiesQuery } from '~/generated/server';
import { useTranslation } from '~/i18n';
import { cn } from '~/utils/cn';
import type { ConnectionResultToNodeArray } from '~/utils/connection-result-to-node-array';
import { convertToStars, formatPrice } from '~/utils/number';
import { Coin } from './Coin';
import { LocationPin } from './icons/LocationPin';
import { Star } from './icons/Star';
import { buttonVariants } from './ui/Button';
import { Skeleton } from './ui/Skeleton';
import { type H4, HeadingM, HeadingXS, textVariants } from './ui/Typography';

type Activity = NonNullable<ConnectionResultToNodeArray<ActivitiesQuery['activities']>>[number];
type PublicActivity = NonNullable<
  ConnectionResultToNodeArray<PublicActivitiesQuery['publicActivities']>
>[number];

interface Props extends Omit<React.ComponentProps<typeof Link>, 'to'> {
  to?: string;
  activity: PublicActivity | Activity;
  imgLoading?: 'eager' | 'lazy';
  fetchPriority?: React.ComponentProps<'img'>['fetchPriority'];
  decoding?: React.ComponentProps<'img'>['decoding'];
  size?: 's' | 'm';
}

export function ActivityCard({
  activity,
  imgLoading = 'lazy',
  fetchPriority = 'auto',
  decoding,
  size = 'm',
  ...props
}: Props) {
  const { t } = useTranslation();

  const isFull = activity.attendeeCount === activity.maxAttendees;
  const isSM = size === 's';

  return (
    <Link
      prefetch="intent"
      to={`/activites-senior/${activity.slug || activity.id}`}
      {...props}
      className={cn(
        'relative flex flex-col overflow-hidden rounded-lg bg-card shadow-[0px_4px_0px_0px_rgba(25,_48,_53,_0.04)]',
        props.className,
      )}
    >
      <div className="relative">
        <Image
          priority
          alt={activity.name}
          src={activity.picture?.url || '/images/placeholder.png'}
          className={cn('h-[180px] w-full rounded-t-lg object-cover object-center', {
            'h-[130px]': isSM,
          })}
          height={isSM ? 130 : 180}
          width={400}
          loading={imgLoading}
          decoding={decoding}
          fetchPriority={fetchPriority}
          cdn="cloudflare_images"
          transformer={(opts) => {
            const url = typeof opts.url === 'string' ? opts.url : opts.url.toString();

            const sp = new URLSearchParams();
            if (opts.width) sp.set('width', String(opts.width));
            if (opts.height) sp.set('height', String(opts.height));
            sp.set('format', opts.format || 'auto');
            sp.set('quality', '80');

            return url.replace(/^(https?:\/\/[^\/]+)/, `$1/cdn-cgi/image/${sp.toString()}/$1`);
          }}
        />
        <div className="flex flex-wrap items-center gap-1 absolute top-2 right-2">
          {isFull ? (
            <ActivityBadge className="border-surface-red bg-surface-red">{t('full')}</ActivityBadge>
          ) : (
            <>
              <ActivityPrice activity={activity} />
              {activity.subscriptionRequired ? (
                <ActivityBadge className="border-2 bg-surface-on">
                  {t('subscription-required')}
                </ActivityBadge>
              ) : null}
            </>
          )}
          {activity.positiveReviewPercentage ? (
            <ActivityBadge>
              <Star className="size-4 mr-1.5" />
              {convertToStars(activity.positiveReviewPercentage)}/5
            </ActivityBadge>
          ) : null}

          {activity.scene === 'ONLINE' ? (
            <ActivityBadge className="border-quaternary bg-quaternary aspect-square px-0 w-[30px]">
              <svg viewBox="0 -960 960 960" fill="currentColor" className="size-4">
                <path d="M320-120v-80h80v-80H160q-33 0-56.5-23.5T80-360v-400q0-33 23.5-56.5T160-840h640q33 0 56.5 23.5T880-760v400q0 33-23.5 56.5T800-280H560v80h80v80H320ZM160-360h640v-400H160v400Zm0 0v-400 400Z" />
              </svg>
            </ActivityBadge>
          ) : null}
        </div>
      </div>
      <div className="flex flex-1 flex-col gap-y-3 p-4">
        <HeadingXS className="line-clamp-2" asChild>
          <h3>{activity.name}</h3>
        </HeadingXS>
        <div
          className={cn(
            textVariants({
              size: 'm',
              className: 'font-medium text-subdued-foreground mt-auto',
            }),
            { 'space-y-1': activity.scene === 'OFFLINE' && !isSM },
          )}
        >
          <p>
            {'date' in activity
              ? dayjs(activity.date).format('DD MMMM')
              : capitalize(dayjs(activity.startTime).calendar())}
            &nbsp; &#x2022; &nbsp;
            {dayjs.duration(activity.duration, 'minute').format('H[h]mm')}
          </p>
          {activity.scene === 'OFFLINE' && !isSM ? (
            <span className="flex items-start gap-1.5">
              <LocationPin className="size-5 min-w-5" />
              <span className="line-clamp-1">
                {activity.areas.map((area) => area?.displayName).join(', ')}
              </span>
            </span>
          ) : null}
        </div>
      </div>
    </Link>
  );
}

export function ActivityBadge({ ...props }: React.ComponentProps<'span'>) {
  return (
    <span
      {...props}
      className={cn(
        'flex items-center justify-center rounded-full border-2 px-3 h-[30px] font-semibold capitalize text-subdued-foreground bg-surface-on',
        textVariants({ size: 's' }),
        props.className,
      )}
    />
  );
}

export function ActivityPlaceholder(props: React.ComponentProps<'div'>) {
  return (
    <div
      {...props}
      className={cn(
        'flex h-full flex-col rounded-lg bg-card shadow-[0px_4px_0px_0px_rgba(25,_48,_53,_0.04)]',
        props.className,
      )}
    />
  );
}

export function ActivityPlaceholderImage(props: React.ComponentProps<typeof Skeleton>) {
  return (
    <Skeleton
      {...props}
      className={cn('h-[220px] w-full rounded-b-none rounded-t-lg', props.className)}
    />
  );
}

export function ActivityPlaceholderContent(props: React.ComponentProps<'div'>) {
  return <div {...props} className={cn('flex flex-col gap-y-4 p-4', props.className)} />;
}

export function ActivityPlaceholderTitle(props: React.ComponentProps<typeof H4>) {
  return <HeadingM {...props} />;
}

export function ActivityPlaceholderAction(props: React.ComponentProps<'div'>) {
  return (
    <div
      {...props}
      className={cn(
        buttonVariants({ variant: 'tertiary' }),
        'w-full shadow-[0px_4px_0px_0px_rgba(25,_48,_53,_0.04)]',
        props.className,
      )}
    />
  );
}

function isPrivate(activity: PublicActivity | Activity): activity is Activity {
  return 'startTime' in activity;
}

function ActivityPrice({
  activity,
}: {
  activity: PublicActivity | Activity;
}) {
  if (!isPrivate(activity) || !activity.price.amount) return null;

  const isDiscounted = activity.viewerPrice.amount !== activity.price.amount;
  const couldBeDiscounted = activity.price.amount !== activity.subscriberPrice.amount;
  const showSubscriberPrice = couldBeDiscounted && !isDiscounted;

  return (
    <div
      className={cn(
        'flex shrink-0 items-center gap-1 rounded-full flex-1 border-2 overflow-hidden bg-surface-on px-2 font-bold h-[30px]',
        textVariants({ size: 's' }),
        { 'pr-0.5': showSubscriberPrice },
      )}
    >
      <FormattedPrice
        price={activity.price}
        className={cn({
          'line-through decoration-2': isDiscounted,
        })}
      />

      {isDiscounted ? (
        <FormattedPrice price={activity.viewerPrice} className="text-[#fb9800]" size="sm" />
      ) : null}

      {showSubscriberPrice ? (
        <div className="bg-border w-full px-2 py-0.5 flex flex-col place-content-center place-items-center rounded-xl">
          <span className="flex gap-1 items-center text-sm">
            <FormattedPrice price={activity.subscriberPrice} className="text-[#fb9800]" size="sm" />
            abonné
          </span>
        </div>
      ) : null}
    </div>
  );
}

export function FormattedPrice({
  price,
  size = 'md',
  options,
  ...props
}: {
  price: Money;
  size?: 'sm' | 'md';
  options?: Intl.NumberFormatOptions;
} & React.ComponentProps<'span'>) {
  if (price.currency === 'COL') {
    return (
      <span {...props} className={cn('flex items-center', props.className)}>
        <Coin className={cn('inline size-4', { 'size-3': size === 'sm' })} />
        <span className="inline-block px-1">{price.amount / 100}</span>
      </span>
    );
  }

  return (
    <span {...props}>
      {formatPrice(price.amount, {
        ...options,
        currency: price.currency,
      })}
    </span>
  );
}
